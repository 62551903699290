import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import heroImage from '../assets/hero.jpg';
import logoYellow from '../assets/logo-yellow.png';

import heroDesktop from '../assets/hero-desktop.jpg';
import heroMobile from '../assets/hero-mobile.jpg';

import icon from '../assets/icon.jpg';
import mobileHeader from '../assets/mobile-header.jpg';
import koala from '../assets/koala.png';

import Aside from '../components/Aside';
import Footer from '../components/Footer/Footer';
import NewsItem from '../components/NewsItem/NewsItem';
import PlayerList from '../components/PlayerList/PlayerList';
import BlackFooter from '../components/BlackFooter/BlackFooter';

// colours
// 
const videoEmbed = <iframe width="560" height="315" src="https://www.youtube.com/embed/M_XwzBMTJaM" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" style={{border: '0px'}}></iframe>;

const newsItem = [
  //{ type: 'video', video: videoEmbed },
  { type: 'paragraph', data: 'In an exciting new announcement, Auscraft, a Starcraft II tournament set to take place in Melbourne on January 10th-12th, has been revealed.' },
  { type: 'paragraph', data: 'Auscraft will feature a selection of international and local Starcraft II talent, competing for a $5000 prize pool. The first invites are set to be revealed in the coming days.' },
  { type: 'paragraph', data: 'The tournament will be streamed live on Twitch across multiple channels, with three action-packed days of competition, banter and embracing gaming and Australian culture.' },
  { type: 'paragraph', data: 'Broadcasting live from an exclusive gamer heaven located near one of Melbourne’s most iconic beaches in Rye, Auscraft is all about combining the casual and social aspect of gaming with a pro gaming tournament.' },
  { type: 'paragraph', data: 'It will go beyond the in-game action to focus on bringing out the characters that make the pro players distinct personalities in their own right. ' },
  { type: 'paragraph', data: 'Through a range of live activities, Auscraft will provide a platform for key personal narratives focused on a unique entertainment experience.' },
  { type: 'paragraph', data: 'A subscription system will unlock additional content for the viewer, allowing fans to support the creation of content that directly appeals to them.' },
  { type: 'paragraph', data: 'The heart and soul of esports is birthed in the shared experiences we have playing games together, “ said Michael Carmody, who leads the operations for ce2pe, the production company behind Auscraft. ‘The origin of all of this is our love for the LAN event.’' },
  { type: 'paragraph', data: 'He added that ‘through Auscraft and future events, we plan to just lean right back to the origins of what we all loved in the first place, being together in the same place for fun, games and talking smack about your opponent.’' },
  { type: 'paragraph', data: 'Carmody also stated that this event is the initial of a series of similar entertainment-focused, house-based events for other titles coming later this year.'},
  { type: 'jsx', data: (<p style={{textAlign: 'left', fontStyle: 'italic'}}>Follow Auscraft on <a href='https://twitter.com/AuscraftGG'>Twitter</a>, <a href='https://www.instagram.com/auscraftgg/'>Instagram</a> and <a href='https://www.facebook.com/AuscraftGG'>Facebook</a> for more information.</p>)}
];

const useStyles = makeStyles(theme => ({
  background: {
    background: '#011607',
  },
  heroBackground: {
    display: 'grid',
    height: '50vh',
    background: `url(${heroDesktop})`,
    backgroundSize: 'cover',
    backgroundPositionY: '-0vh',
    [theme.breakpoints.down('md')]: {
      // put responsive image here
      //background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,23,14,1) 0%, rgba(5,44,25,1) 100%)',
      //height: 'auto',
      background: `url(${heroMobile})`,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPositionY: '-15vh',
      height: '25vh',
    },
    color: '#FFCE00',
  },
  mobileHero: {
    width: '100%',
    height: 'auto',
    visibility: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  heading: {
    width: '100%',
    textAlign: 'center',
    padding: 'auto 20px',
    height: 'auto',
  },
  section: {
    background: '#011607',

  },
  gallery: {
    width: '80vw',
    marginLeft: '10vw',
    paddingBottom: '20px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    '& img': {
      width: '98%',
      margin: '1%',
      height: 'auto',
      border: '2px solid white',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.02)',
      },
    },
  },
  newsContainer: {
    display: 'grid',
    gridTemplateColumns: '100vw',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  logoOverlay: {
    width: '35%', 
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    
  },

}));

const images = [
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
  koala,
];

interface Props {

};

const Homepage = ({

}: (Props)) => {
  const classes = useStyles();

  useEffect(() => {
    document.title = `Auscraft`;
    let body = document.getElementById("body");
    if (body) {
      console.log('got body');
      body.className = classes.background;
    }
    console.log('did not got body');
  });

  return (
    <>
      <div className={classes.heroBackground}>
        <img className={classes.logoOverlay} src={logoYellow}></img>
      </div>


      <div className={classes.newsContainer}>
        <NewsItem
          heading='Announcing Auscraft'
          //headingImage={koala}
          newsPostContent={newsItem}
          date='8th December 2019'
          gridView
        ></NewsItem>
      </div>

      <BlackFooter></BlackFooter>
    </>
  )
}

export default Homepage;

//<div className={classes.section}>
//        <Aside image={icon} heading='Trophy to be won' text='Gosu gamers from across the globe!' index={1}></Aside>
//        <Aside image={icon} heading='Trophy to be won' text='Gosu gamers from across the globe!' index={2}></Aside>
//        <Aside image={icon} heading='Trophy to be won' text='Gosu gamers from across the globe!' index={3}></Aside>
//
//        <div className={classes.gallery}>
//          {images.map(image => {
//            return(<img src={image}></img>);
//          })}
//        </div>
//      </div>
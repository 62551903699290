import Homepage from '../views/Homepage';
import Streams from '../views/Streams';

const indexRoutes = [
    
    { 
      path: '/streams',
      component: Streams
    },
    //default component (base url and non indexed routes)
    {
      path: '/',
      component: Homepage
    },
    
  ];

export default indexRoutes;
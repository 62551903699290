import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import heroDesktop from '../assets/hero-desktop.jpg';
import { Button } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
  background: {
    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,23,14,1) 0%, rgba(5,44,25,1) 100%)',
  },

  streamContainer: {
    position: 'relative',
    width: '100%',
    marginTop: '10px',
    background: 'white',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    '& iframe': {
      border: '0',      
    },
  },
  streamTabs: {
    paddingTop: '20px',
    height: '5%',
    textAlign: 'center',
    
    '& button': {
      marginRight: '10px',
      background: 'white',
      '&:hover':{
        background:'white',
      },
    },
  },
  activeChat: {
    display: 'block',
  },
  inactiveChat: {
    display: 'none',
  },

}));

interface Props {

};

const Streams = ({

}: (Props)) => {
  const classes = useStyles();
  const channels = [
    'auscraftgg',
    'auscraftgg_b',
    'auscraftgg_c'
  ];

  useEffect(() => {
    document.title = `Streams - Auscraft`;
    let body = document.getElementById("body");
    if(body){
      body.className = classes.background;
    }
  });

  const [channelIndex, setChannelIndex] = useState(0);

  const getClass = (index: number) => {
    if(index===channelIndex){
      return(classes.activeChat)
    } else {
      return(classes.inactiveChat)
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.streamTabs}>
        <Button onClick={()=>setChannelIndex(0)}>One</Button>
        <Button onClick={()=>setChannelIndex(1)}>Two</Button>
        <Button onClick={()=>setChannelIndex(2)}>Three</Button>
      </div>
      <div className={classes.streamContainer}>
        <iframe allowFullScreen={true} src={`https://player.twitch.tv/?channel=${channels[channelIndex]}`} scrolling="no" height="100%" width="100%"></iframe>

        <iframe className={getClass(0)} src={`https://www.twitch.tv/embed/${channels[0]}/chat`} scrolling="no" height="100%" width="100%"></iframe>
        <iframe className={getClass(1)} src={`https://www.twitch.tv/embed/${channels[1]}/chat`} scrolling="no" height="100%" width="100%"></iframe>
        <iframe className={getClass(2)} src={`https://www.twitch.tv/embed/${channels[2]}/chat`} scrolling="no" height="100%" width="100%"></iframe>
      </div>
    </div>
  )
}

export default Streams;
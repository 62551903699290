import React, { useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles( theme => ({
  newsPostContainer: {    
    margin: '50px 0px 50px 20%',
    background: '#243B4A',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      margin: '15px 0px 15px 10%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0px',
    },
    transition: 'width, margin ease 0.5s',
    color: 'white',
    textAlign: 'center',
  },
  gridViewContainer: {
    borderRadius: '5px',
    textAlign: 'center',
    background: '#011607',
    color: 'white',
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '25px',
    },
    '& a': {
      color: '#FECE00',
    },
  },
  heading: {
    textTransform: 'uppercase',
    padding: '20px',
    fontSize: '2em',
    width: '100%',
    marginLeft: '0%',
    color: '#FFCE00',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    '& h2': {
      //color: 'rgba(255,226,90,1)',
      margin: '0%',
      fontFamily: 'Open Sans Condensed',
      fontWeight: 'bold',
      
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
  },
  subHeading: {
    width: '70%',
    marginLeft: '15%',
  },
  newsPostCard: {
    fontSize: '1.25em',
    margin: 'auto',
    width: '65%',
    marginBottom: '20px',
  },
  postHeading: {
    textTransform: 'uppercase',
  },
  postBody: {
    textAlign: 'left',
  },
  date: {
    fontSize: '0.7em',
    margin: '0px 0px 20px 3%',
    padding: '0',
    textAlign: 'left',
  },
  image: {
    width: '100%',
    textAlign: 'center',
    '& img': {
      width: '100%',
    },
  },
  link: {
    width: '100%',
    color: '#E31937',
  },
  youtubeWrapper: { // wrapper so video resizes with window
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    overflow: 'hidden',
    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
  },
}));

type Link = {
  link: string,
  linkText: string,
}

interface PostElement {
  type: string; //"paragraph" | "image" | "video", string literal is not working // video in the format of a youtube embed
  data?: string | JSX.Element;
  video?: JSX.Element
  link?: Link;
}

interface Props {
  heading: string;
  subHeading?: string;
  headingImage?: string;
  newsPostContent: PostElement[];
  date: string;
  gridView?: boolean; //if true displays in a compact form inline with other news items
};


// -when embedding youtube videos, default youtube embed copy paste includes deprecated 'frameborder' attribute - all it does is set border=0
// but it will throw an error if present, as will 'allowfullscreen', workaround is to add 'fullscreen' to the allow attribute
// the above will cause problems, best solution is to parse the embed code prior to render to remove the garbage 
const NewsItem = (
  ({
    heading,
    subHeading,
    headingImage,
    newsPostContent,
    date,
    gridView,
  }: (Props)) => {

    useEffect(() => {
      document.title = `META - ${heading}`;
    });

    const classes = useStyles();

    function GetContentByType(content: PostElement): string | JSX.Element { // returns a jsx element to display 
      if (content.type === 'paragraph') {
        return (<p className={classes.postBody}>{content.data}</p>)
      } else if (content.type === 'image' && content.data) {
        return (<div  className={classes.image}><img src={content.data.toString()} alt=''></img></div>)
      } else if (content.type === 'video' && content.video) {
        return (<div className={classes.youtubeWrapper}>{content.video}</div>);
      } else if (content.type === 'link' && content.link) {
        return (<a className={classes.link} href={content.link.link}>{content.link.linkText}</a>)
      } else if (content.type === 'jsx' && content.data) {
        return (content.data)
      }
      else {
        //ERROR
        return 'undefined';
      }
    }

    return (
      
      <div className={gridView ? (classes.gridViewContainer) : (classes.newsPostContainer)}>
        <div className={classes.heading}>
          <h2>{heading}</h2>
          {subHeading && (
            <h3 className={classes.subHeading}>{subHeading}</h3>
          )}
          {headingImage && (
            <img src={headingImage} style={{width: '65%', marginTop: '4%'}} alt='heading'></img>
          )}
        </div>
        <div className={classes.newsPostCard}>
          {newsPostContent.map((post) => [
            GetContentByType(post)
          ])}

          <div style={{borderBottom: '2px solid #FFCE00', width: '20%', margin: 'auto', marginTop: '10%'}}></div>

        </div>


      </div>

    )
  });

export default NewsItem;

import React, { Component } from 'react';
import indexRoutes from './routes/index';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';


import Homepage from './views/Homepage';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        {indexRoutes.map(prop => {
          return (
            <Route
              path={prop.path}
              component={prop.component}
              key={prop.path}
            />
          );
        })}
      </Switch>
    </Router>
  );
}

export default App;

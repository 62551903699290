import React, { useState, } from 'react';

import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';


import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  footerContainer: {
    width: '100%',
    height: 'auto',
    boxShadow: '0px -1px 12px 1px black',
    //-webkit-box-shadow: 0px -1px 17px 5px rgba(255,226,90,0.61);
    //-moz-box-shadow: 0px -1px 17px 5px rgba(255,226,90,0.61);
    //box-shadow: ;
  },

  footerLinks: {
    
    textAlign: 'center',
    background: '#030303',
    boxSizing: 'border-box',
    padding: '10px 2% 10px 2%',
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: '1fr 1fr',
      gridTemplateColumns: '100vw',
    },

  },
  linksColumn: {
    '& a': {
      color: 'white',
      textDecoration: 'none',
      fontSize: '1.25em',
      fontWeight: 'bold',
      marginLeft: '20px',
      [theme.breakpoints.down('md')]: {
        fontSize:'1em',
      },
      transition: '250ms ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)',
        textDecoration: 'underline',
      },
    },
    padding: '10px 3% 0% 3%',
    [theme.breakpoints.down('md')]: {
      padding: '15px 3% 5% 3%',
      textAlign: 'center',
    }
  },
  socials: {
    verticalAlign: 'top',
    display: 'inline-block',
    '& svg': {
      color: '#FECE00',
      fontSize: '1.5em',
      marginRight: '15px',
      [theme.breakpoints.down(600)]: {
        fontSize: '1.25em',
        marginRight: '10px',
      },
    },
    
    
  },
  footerIcon: {
    fontSize: '1.5em',
    padding: '0px',
    margin: '0px'
  },
  contactSection: {
    background: '#010B03',
    width: '100%',
    height: '300px',
    color: '#FFFFFF',
    '& h2': {
      width: '75%',
      fontSize: '2.5em',
      color: '#FECE00',
      [theme.breakpoints.down(600)]: {
        fontSize: '1.75em',
        width: '80%',
      },
    },
    clipPath: 'polygon(0 0, 100% 33.3%, 100% 100%, 0% 100%)',
  },
  contactSectionContent:{
    width: '65%',
    paddingTop: '50px',
    margin: 'auto',
  },
}));

interface Props {
};

const BlackFooter = (
  ({

  }: (Props)) => {

    const classes = useStyles();

    return (
      <>

        <div className={classes.contactSection}>
          <div className={classes.contactSectionContent}>
            <h2>Games Start 10th of January</h2>
            <div className={classes.socials}>
                <IconButton href="https://twitter.com/auscraftgg" className={classes.footerIcon}>
                  <TwitterIcon></TwitterIcon>
                </IconButton>
                <IconButton href="https://instagram.com/auscraftgg" className={classes.footerIcon}>
                  <InstagramIcon></InstagramIcon>
                </IconButton>
                <IconButton href="https://facebook.com/auscraftgg" className={classes.footerIcon}>
                  <FacebookIcon></FacebookIcon>
                </IconButton>
              </div>
          </div>
          
        </div>
        
      </>

    )

  });

export default BlackFooter;

//<div className={classes.footerContainer}>
//
//<div className={classes.footerLinks}>
//  <div className={classes.linksColumn}>
//    
//    <a href='/streams'>Streams</a>
//  </div>
//  <div >
//  </div>
//</div>
//</div>